import { gift_alert } from "./template_giftInfo_modules/gift_alert";

export function template_gift(dbInfo, dynamicValue) {
    const elements = {
        placeholderGift: document.querySelector('.placeholder_gift'),
        giftItemWrap: document.querySelector('.gift_item_contents_item_wrap'),
        giftContentsWrap: document.querySelector('.section_gift_contents_wrap'),
        flowerFloatingBtn: document.querySelector('.flower_floatingBtn'),
        giftSection: document.getElementById('giftSection'),
        toggleDDay: document.getElementById('toggleSwitch_gift'),
    };


    const displayElement = (element, displayStyle) => {
        if (element) element.style.display = displayStyle;
    };

    const updateGiftDisplay = () => {
        if (dbInfo.gift_status === 1) {
            displayElement(elements.placeholderGift, "none");
            displayElement(elements.giftItemWrap, "flex");
            displayElement(elements.giftContentsWrap, "flex");

            if (dbInfo.gift_flower_type === 'flower_banner') {
                displayElement(elements.flowerFloatingBtn, "none");
                displayElement(elements.giftSection, "flex");
            } else {
                displayElement(elements.flowerFloatingBtn, "flex");
                displayElement(elements.giftSection, "none");
            }
        } else {
            displayElement(elements.placeholderGift, "flex");
            displayElement(elements.giftItemWrap, "none");
            displayElement(elements.giftContentsWrap, "none");
            displayElement(elements.flowerFloatingBtn, "none");
            displayElement(elements.giftSection, "none");
        }
    };

    const handleFlowerImgChange = (element) => {
        const value = element.getAttribute('data-value');
        console.log('Effect selected:', value);

        const sectionGiftContentsWrapImg = document.querySelector('.section_gift_contents_wrap_img');

        if (sectionGiftContentsWrapImg) {
            if (value === 'flower_banner') {
                sectionGiftContentsWrapImg.src = "../../../Resource/templates/controls/gift/flower_banner.png";
                displayElement(elements.giftSection, "flex");
                displayElement(elements.flowerFloatingBtn, "none");
            } else if (value === 'flower_floatingBtn') {
                sectionGiftContentsWrapImg.src = "../../../Resource/templates/controls/gift/flower_floatingBtn.png";
                displayElement(elements.giftSection, "none");
                displayElement(elements.flowerFloatingBtn, "flex");
            }
        }
    };

    const addActiveClass = (elements, callback) => {
        elements.forEach((element) => {
            element.addEventListener('click', function () {
                elements.forEach(el => el.classList.remove('active'));
                this.classList.add('active');
                if (callback) callback(this);
            });
        });
    };

    const toggleGiftDisplay = () => {
        elements.toggleDDay.classList.toggle('active');
        const isActive = elements.toggleDDay.classList.contains('active');
        const displayStyle = isActive ? 'flex' : 'none';

        const giftClasses = document.querySelectorAll(".giftSection");

        giftClasses.forEach(giftClass => {
            displayElement(giftClass, displayStyle);
        });

        displayElement(elements.giftItemWrap, displayStyle);
        displayElement(elements.giftContentsWrap, displayStyle);

        if (dbInfo.gift_flower_type === 'flower_banner') {
            displayElement(elements.flowerFloatingBtn, 'none');
            displayElement(elements.giftSection, displayStyle);
        } else {
            displayElement(elements.flowerFloatingBtn, displayStyle);
            displayElement(elements.giftSection, 'none');
        }

        displayElement(elements.placeholderGift, isActive ? 'none' : 'flex');
    };

    // 초기 설정
    updateGiftDisplay();

    // 이벤트 리스너
    const giftItemButtons = document.querySelectorAll('.gift_item_contents_item_button_effect');
    addActiveClass(giftItemButtons, handleFlowerImgChange);

    if (elements.toggleDDay) {
        elements.toggleDDay.addEventListener('click', toggleGiftDisplay);
    }

    // 선물 알림 표시
    gift_alert(dynamicValue);
}
