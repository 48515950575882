export function menuSlide() {
  let menuList = document.querySelector('.header_menuList');
  let mobileMenu = document.querySelector('.mobileMenu');
  let BGdimmed = document.querySelector("#menuBG.BgDimmed");

  function checkWindowSize() {
    if (window.innerWidth >= 890) {
      BGdimmed.style.display = "none";
      menuList.style.transform = "translateY(-464px)";

      if (mobileMenu.classList.contains('is-active')) {
        mobileMenu.classList.remove('is-active');
      }

      document.body.style.overflow = 'auto';
    }
  }

  if (BGdimmed) {
    BGdimmed.style.cursor = "pointer";
    BGdimmed.addEventListener('click', () => {
      if (menuList.style.transform === "translateY(-64px)") {
        menuList.style.transform = "translateY(-464px)";
        BGdimmed.style.display = "none";
        mobileMenu.classList.remove('is-active');
        document.body.style.overflow = 'auto';
      }
    });
  }

  if (mobileMenu) {
    mobileMenu.addEventListener('click', () => {
      mobileMenu.classList.toggle('is-active');

      if (menuList.style.transform === "translateY(-64px)") {
        menuList.style.transform = "translateY(-464px)";
        BGdimmed.style.display = "none";
        document.documentElement.style.overflow = 'auto';
        document.body.style.overflow = 'auto';
      } else {
        menuList.style.transform = "translateY(-64px)";
        BGdimmed.style.display = "block";
        BGdimmed.style.zIndex = "1";
        document.documentElement.style.overflow = 'hidden';
        document.body.style.overflow = 'hidden';
      }
    });

    window.addEventListener('resize', checkWindowSize);
    checkWindowSize();  // 초기 로딩 시 실행
  }
}