import { QNASlide } from '../components/QNASlide.js';
import { shareKakao } from '../utils/kakaoShare.js';

document.addEventListener("DOMContentLoaded", function () {

    const currentUrl = window.location.href;
    const pathArray = currentUrl.split('/');
    const dynamicValue = pathArray[pathArray.length - 1]; // 마지막 요소인 동적인 값
    const disableMainPageUrl = `${pathArray[0]}//${pathArray[2]}/`; // 동작하지 않아야 할 URL
    const disableCreateInvitationUrl = `${pathArray[0]}//${pathArray[2]}/api_CreateTemplate/${dynamicValue}`; // 동작하지 않아야 할 URL
    const disableEditInvitationUrl = `${pathArray[0]}//${pathArray[2]}/api_EditInvitation/${dynamicValue}`; // 동작하지 않아야 할 URL
    const disableAuth = `${pathArray[0]}//${pathArray[2]}/api_Auth/login`; // 동작하지 않아야 할 URL
    const disableUrl = `${pathArray[0]}//${pathArray[2]}/api_GetInvitation/${dynamicValue}`; // 동작하지 않아야 할 URL

    if (currentUrl === disableMainPageUrl || currentUrl === disableCreateInvitationUrl || currentUrl === disableEditInvitationUrl || currentUrl === disableAuth || currentUrl === disableUrl) {
        return;
    } else {
            console.log(document.querySelectorAll('.tablinks'));
            // 탭 클릭 이벤트 리스너 추가
            document.querySelectorAll('.tablinks').forEach(tab => {
                tab.addEventListener('click', function (event) {
                    console.log('탭 클릭됨:', event.currentTarget); // 디버깅용 로그
    
                    // 모든 탭 콘텐츠를 숨김
                    document.querySelectorAll('.tabcontent').forEach(content => {
                        content.style.display = 'none';
                    });
    
                    // 모든 탭 링크에서 active 클래스 제거
                    document.querySelectorAll('.tablinks').forEach(link => {
                        link.classList.remove('active');
                    });
    
                    // 클릭된 탭 링크에 active 클래스 추가
                    event.currentTarget.classList.add('active');
    
                    // 클릭된 탭에 해당하는 콘텐츠를 표시
                    const tabName = event.currentTarget.getAttribute('data-tab');
                    console.log('표시할 탭 콘텐츠 ID:', tabName); // 디버깅용 로그
                    document.getElementById(tabName).style.display = 'block';
                });
            });
    


        let myPageKakaoShare = document.querySelectorAll('.kakaoShareWrap');
        // 카카오 SDK 초기화
        Kakao.init('aead73005a77433d268644553628caba');
        myPageKakaoShare.forEach(element => {
            element.addEventListener('click', function () {
                // Find the closest common container or parent
                let parentContainer = element.closest('.InviteTailWrap');

                if (parentContainer) {
                    let myPageTemplateID = parentContainer.querySelector('.InviteURLInfo');
                    if (myPageTemplateID) {
                        let templateId = myPageTemplateID.innerText.replace(/\s+/g, '');

                        console.log(templateId);
                        // Make a GET request to the specified URL
                        shareKakao(templateId)

                    }
                } else {
                    console.log("No parent container found for this kakaoShareWrap element.");
                }
            });
        });

        // 마이페이지 Helper Text
        let myPageHelperText = document.querySelector('.myPageHelperBtn');
        let BgDimmed = document.getElementById('Helper_Dimmed');
        let InfoArea = document.querySelector('.CautionInfo');
        let helperExit = document.getElementById('helperExit');


        // BGdimmed 요소 클릭했을 때의 동작을 정의
        if (myPageHelperText) {
            myPageHelperText.addEventListener('click', () => {
                BgDimmed.style.display = "flex"
                InfoArea.style.display = "flex"
                InfoArea.style.maxWidth = "400px"
                InfoArea.style.padding = "24px"
                helperExit.style.display = "block"
            });
        }

        if (helperExit) {
            helperExit.addEventListener('click', () => {
                BgDimmed.style.display = "none"
                InfoArea.style.display = "none"
                helperExit.style.display = "none"
            });
        }

        // 필요한 DOM 요소들을 선택하여 변수에 할당
        const myPageTitle = document.querySelector('.myPageTitle');

        // 서버에서 세션 정보를 가져오는 함수
        async function getSessionData() {
            try {
                console.log('세션 정보를 가져오는 중...'); // 디버깅
                const response = await fetch('/api_GetSession');
                if (response.ok) {
                    const data = await response.json();
                    console.log('세션 정보:', data); // 디버깅
                    return data;
                } else {
                    throw new Error('Failed to fetch session data');
                }
            } catch (error) {
                console.error('세션 정보를 가져오는 중 오류 발생:', error);
                return null;
            }
        }

        // 로그인 ID 마이페이지 닉네임에 매핑
        getSessionData().then(sessionData => {
            if (sessionData && sessionData.naverEmail) {
                let loginID = sessionData.naverEmail.split('@')[0]; // 이메일에서 ID 추출
                if (myPageTitle) {
                    myPageTitle.innerHTML = `@${loginID}`;
                }
            } else {
                console.log('로그인된 사용자가 아닙니다.'); // 디버깅
            }
        });


        // Mypage URL 복사 로직
        let myPageInviteCopy = document.querySelectorAll('.InviteURLWrap');
        myPageInviteCopy.forEach(function (myPageInviteCopyBtn) {
            myPageInviteCopyBtn.addEventListener('click', function () {
                console.log(myPageInviteCopyBtn);
                console.log(myPageInviteCopy);
                let inviteURLInfoElement = myPageInviteCopyBtn.parentNode.querySelector('.InviteURLInfo');
                console.log('inviteURLInfoElement:', inviteURLInfoElement);

                if (inviteURLInfoElement) {
                    let inviteInfo = inviteURLInfoElement.innerText.trim();
                    let currentPageUrl = window.location.href;
                    let baseUrl = currentPageUrl.split('/api_Mypage')[0];
                    let newUrl = `${baseUrl}/api_GetInvitation/${inviteInfo}`;

                    console.log('newUrl:', newUrl);

                    if (navigator.clipboard) {
                        navigator.clipboard.writeText(newUrl).then(function () {
                            alert('청첩장 URL 이 복사되었습니다. 이제 공유 버튼을 눌러주세요.');
                        }, function (err) {
                            console.error('Failed to copy: ', err);
                        });
                    } else {
                        console.error('Clipboard API is not supported');
                    }
                } else {
                    console.error('inviteURLInfoElement not found');
                }
            });
        });




        // 계좌번호 복사

        let copyAccounts = document.querySelectorAll('.accodian-btninfo');
        copyAccounts.forEach(function (copyAccount) {
            copyAccount.addEventListener('click', function () {
                let accodianInfo = this.closest('.accodian-inner');
                let bankNameElement = accodianInfo.querySelector('.accountinfo');
                if (bankNameElement) {
                    let bankName = bankNameElement.innerText;
                    // 모든 개행문자를 공백으로 바꾸기
                    bankName = bankName.replace(/\n/g, ' ');
                    let value = bankName;
                    navigator.clipboard.writeText(value).then(function () {
                        alert('계좌번호가 복사되었습니다.');
                    }, function (err) {
                        console.error('Failed to copy: ', err);
                    });
                }
            });
        });

        // 이후의 공유팝업 띄우기 함수
        function showSharePopup(urlToShare, inviteTypeTitle) {
            // 데이터를 준비
            let shareData = {
                title: `${inviteTypeTitle}`,
                text: `${inviteTypeTitle}`, // InviteTypeTitle을 텍스트에 추가
                url: urlToShare
            };

            // navigator.share를 사용하여 시스템 공유팝업 띄우기
            if (navigator.share) {
                navigator.share(shareData)
                    .then(() => {
                        console.log('성공적으로 공유됨');
                    })
                    .catch((error) => {
                        console.error('공유 중 오류 발생', error);
                    });
            } else {
                alert('이 브라우저는 공유 기능을 지원하지 않습니다. 직접 공유 버튼을 누르세요.');
            }
        }


        // 마이페이지에서 Get 요청을 통한 청첩장 미리보기 페이지 불러오기 api
        let MYpage_InvitePreview = document.querySelectorAll('.BtnPreview');

        MYpage_InvitePreview.forEach((preview) => {
            preview.addEventListener('click', () => {
                // `.HistoryItemWrapHead` 조상을 찾은 후 `.InviteURLInfo` 선택
                const inviteURLInfoList = preview.closest('.HistoryWrap').querySelector('.InviteURLInfo');
        
                let GetURLInfo = inviteURLInfoList.textContent.trim();
        
                // 페이지를 이동시킴
                window.location.href = `/api_GetInvitation/${GetURLInfo}`;
            });
        });
        

        document.querySelectorAll('#BtnWaterMark').forEach((watermarkDel) => {
            watermarkDel.addEventListener('click', () => {

                const naverAccessToken = sessionStorage.getItem('naver_access_token');
                const naverEmail = sessionStorage.getItem('naver_email');

                const inviteURLInfoList = watermarkDel.closest('.HistoryWrap').querySelector('.InviteURLInfo');

                let getURLInfo = inviteURLInfoList.textContent.trim();

                // 로그인 기록이 있는 경우
                if (naverAccessToken && naverEmail) {
                    // api_getCheckOut 경로로 리디렉션
                    window.location.href = `/api_getCheckOut/?info=${encodeURIComponent(getURLInfo)}`; // templateId는 앞서 설정한 템플릿 ID 변수입니다.
                } else {
                    // 로그인 기록이 없는 경우 로그인 페이지로 리디렉션
                    window.location.href = '/api_Auth/login';
                }

                // 페이지를 생성된 URL로 리디렉션
                window.location.href = url;

            });
        });




        // 마이페이지에서 Get 요청을 통한 수정 페이지 불러오기 api
        let btnEditList = document.querySelectorAll('.BtnEdit');

        btnEditList.forEach((btnEdit) => {
            btnEdit.addEventListener('click', () => {
                const inviteURLInfoList = btnEdit.closest('.HistoryWrap').querySelector('.InviteURLInfo');

                let EditURLInfo = inviteURLInfoList.textContent.trim();


                // 새로운 브라우저 창에서 수정 페이지 렌더링
                let editPage = window.open(`/api_EditInvitation/${EditURLInfo}`);

                // 새 창 로드가 완료되면 fetch 요청 보내기
                editPage.addEventListener('load', () => {
                    fetch(`/api_EditInvitation/${EditURLInfo}`, {
                        method: 'GET'
                    })
                        .then(response => {
                            console.log(response);
                            return response.text();
                        })
                        .catch(error => {
                            console.error('Error:', error);
                        });
                });

            });
        });

        // Q&A 슬라이드 모듈 임포트
        QNASlide();



        // RSVP 엑셀 다운로드 버튼 이벤트 리스너 추가
        const excel_download_buttons = document.querySelectorAll('.mypage_section_rsvp_contents_excel_download_buttons_wrap');

        excel_download_buttons.forEach(function (button) {
            button.addEventListener('click', function () {
                // EditURLInfo을 가져와서 templateID 추출
                const inviteURLInfoList = button.closest('.HistoryWrap').querySelector('.InviteURLInfo');
                let EditURLInfo = inviteURLInfoList.textContent.trim();

                // 다운로드 URL 생성 및 fetch 요청
                var downloadUrl = `/api_rsvp/excel/${EditURLInfo}`;
                fetch(downloadUrl)
                    .then(response => response.blob())
                    .then(blob => {
                        var url = window.URL.createObjectURL(blob);
                        var a = document.createElement('a');
                        a.href = url;
                        a.download = 'rsvp_attendance.xlsx';
                        document.body.appendChild(a);
                        a.click();
                        a.remove();
                    })
                    .catch(error => console.error('Error downloading the file:', error));
            });
        });


        // history_btn_delete 버튼 클릭 이벤트 리스너 등록
        document.querySelectorAll('.history_btn_delete').forEach(function (button) {
            button.addEventListener('click', function () {
                // 삭제 확인 메시지 표시
                const isConfirmed = confirm('삭제된 청첩장은 되돌릴 수 없습니다. 정말 삭제하시겠습니까?');

                if (!isConfirmed) {
                    // 사용자가 취소를 선택한 경우, 아무 작업도 하지 않음
                    return;
                }

                // history_btn_delete 버튼의 data-value 가져오기
                const templateID = this.getAttribute('data-value');
                console.log(templateID);

                // DELETE 요청 보낼 API 경로
                const apiPath = `/api_delete_invitation/${templateID}`;

                // DELETE 요청 보내기
                fetch(apiPath, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(response => {
                        console.log('Response status:', response.status); // 상태 코드 확인
                        if (!response.ok) {
                            return response.json().then(data => {
                                // 서버에서 반환한 메시지를 사용해 에러 처리
                                throw new Error(data.message || `Network response was not ok, status code: ${response.status}`);
                            });
                        }
                        return response.json(); // JSON 응답 처리
                    })
                    .then(data => {
                        // 요청 성공 시 처리할 코드
                        console.log('DELETE 요청 성공', data);
                        alert(`청첩장이 삭제되었습니다. 페이지가 새로고침 됩니다.`);
                        // 페이지 새로고침
                        location.reload();
                    })
                    .catch(error => {
                        // 요청 실패 시 처리할 코드
                        console.error('DELETE 요청 실패', error);
                        if (error.message === '삭제할 수 없는 상태입니다.') {
                            alert('영구청첩장은 삭제할 수 없습니다.');
                        } else {
                            alert(`오류가 발생했습니다: ${error.message}`);
                        }
                    });
            });
        });

        // 웨딩 포스터 편집 버튼 이벤트 리스너 추가
        let btnEditWeddingPosterList = document.querySelectorAll('.BtnEditWeddingPoster');

        btnEditWeddingPosterList.forEach((btnEdit) => {
            btnEdit.addEventListener('click', () => {
                const templateIDElement = btnEdit.closest('.HistoryWrap').querySelector('.TemplateIDInfo');
                let templateID = templateIDElement.textContent.trim();

                // 새로운 브라우저 창에서 수정 페이지 열기
                let editPage = window.open(`/wedding-poster-editor/${templateID}`, '_blank');

                // 새 창 로드가 완료되면 fetch 요청 보내기
                editPage.addEventListener('load', () => {
                    fetch(`/api_EditWeddingPoster/${templateID}`, {
                        method: 'GET'
                    })
                        .then(response => {
                            console.log(response);
                            return response.text();
                        })
                        .catch(error => {
                            console.error('Error:', error);
                        });
                });
            });
        });


    }

})