export function musicControl(templateId) {
  let music; // 전역 범위에 music 변수 선언
  let playing = false;
  const buttonpauses = document.querySelectorAll('.audiobuttonpause');
  const buttonplays = document.querySelectorAll('.audiobutton');

  console.log('오디오 체크');
  let audioControls = document.querySelectorAll('.AudioControl'); // 오디오 컨트롤 요소 선택

  // 초기 상태 설정: 'buttonpause' 보이게, 'buttonplay' 숨기게
  buttonpauses.forEach(button => button.style.display = "block");
  buttonplays.forEach(button => button.style.display = "none");

  // 오디오 토글 함수
  const toggleMusic = function () {
    if (music) {
      if (!playing) {
        buttonpauses.forEach(button => button.style.display = "none");
        buttonplays.forEach(button => button.style.display = "block");
        music.muted = false;
        playing = true;
        music.play();
      } else {
        buttonpauses.forEach(button => button.style.display = "block");
        buttonplays.forEach(button => button.style.display = "none");
        music.muted = true;
        playing = false;
        music.pause();
      }
    }
  };

  // 오디오 로드 함수
  async function loadAudio() {
    try {
      // 첫 번째 API에서 BGM_type을 가져옴
      const response = await fetch(`/api_DBInsert/${templateId}`, { method: 'GET' });
      const data = await response.json();
      const BGM_type = data.BGM_type;

      // BGM_type이 null 또는 undefined인 경우 처리
      if (!BGM_type || typeof BGM_type !== 'string') {
        console.error('BGM_type이 유효하지 않습니다.');
        audioControls.forEach(audioControl => {
          audioControl.style.display = "none"; // 오디오 컨트롤 숨김
        });
        return;
      }

      console.log(BGM_type);

      let musicUrl;

      // BGM_type이 URL 형태인지 확인
      if (BGM_type.startsWith('http://') || BGM_type.startsWith('https://')) {
        musicUrl = BGM_type; // BGM_type 자체가 URL인 경우
      } else {
        // 두 번째 API에서 오디오 URL 목록을 가져옴
        const bgmResponse = await fetch('/api_GetBGM', { method: 'GET' });
        const bgmData = await bgmResponse.json();

        // BGM_type에 따라 오디오 URL 결정
        const bgmIndex = parseInt(BGM_type.replace('BaseAudio_', '')) - 1;
        musicUrl = bgmData[bgmIndex];
      }

      if (!musicUrl) {
        audioControls.forEach(audioControl => {
          audioControl.style.display = "none";
        });
        return;
      }

      // 오디오 파일 로드
      music = new Audio(musicUrl); // 전역 변수에 오디오 할당
      music.muted = false; // 음악을 음소거 해제하고 로드
      playing = false; // 초기 상태는 재생되지 않음

      // 카카오톡 브라우저 확인
      const isKakaoBrowser = /KAKAOTALK/i.test(navigator.userAgent);

      if (isKakaoBrowser) {
        // 카카오톡 브라우저에서는 자동 재생
        await music.play();
        playing = true;
        buttonpauses.forEach(button => button.style.display = "none");
        buttonplays.forEach(button => button.style.display = "block");
      }

      // 각 AudioControl 요소에 이벤트 리스너 등록
      audioControls.forEach(audioControl => {
        audioControl.addEventListener('click', toggleMusic);
        audioControl.style.display = "block"; // 오디오 URL이 유효하면 컨트롤 표시
      });

      // 비디오와 음악 동기화 설정
      setupVideoSync();

    } catch (error) {
      console.error('오류:', error);
    }
  }

  // 비디오 재생/일시정지와 음악 동기화 함수
  function setupVideoSync() {
    const videoElement = document.querySelector('.video_insert_wrap video'); // 비디오 요소 참조
    if (videoElement) {
      videoElement.addEventListener('play', () => {
        if (music && playing) {
          music.pause();
          playing = false;
          console.log('비디오 재생: 배경 음악 일시정지');
          buttonpauses.forEach(button => button.style.display = "block");
          buttonplays.forEach(button => button.style.display = "none");
        }
      });

      videoElement.addEventListener('pause', () => {
        if (music && !playing) {
          music.play();
          playing = true;
          console.log('비디오 일시정지: 배경 음악 재생');
          buttonpauses.forEach(button => button.style.display = "none");
          buttonplays.forEach(button => button.style.display = "block");
        }
      });

      videoElement.addEventListener('ended', () => {
        if (music && !playing) {
          music.play();
          playing = true;
          console.log('비디오 종료: 배경 음악 재생');
          buttonpauses.forEach(button => button.style.display = "none");
          buttonplays.forEach(button => button.style.display = "block");
        }
      });
    }
  }

  // 페이지 로드 시 오디오 로드
  loadAudio();
}
