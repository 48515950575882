export function select_coupon() {
    document.getElementById('coupon_check_button').addEventListener('click', function () {
        const couponInput = document.querySelector('.checkoutSection_coupon_input').value;

        fetch('/get_coupon_info')
            .then(response => response.json())
            .then(data => {
                const couponData = data.couponData;
                
                // 입력한 쿠폰 코드와 일치하는 쿠폰 찾기
                const matchingCoupon = couponData.find(coupon => coupon.code === couponInput);

                if (matchingCoupon) {
                    alert('쿠폰 등록에 성공했습니다.');

                    // discount_price에서 소수점 제거
                    const discountPriceFormatted = parseFloat(matchingCoupon.discount_price).toFixed(0);

                    // 쿠폰 HTML 생성
                    const couponHTML = `
                        <div class="checkoutSection_coupon_item">
                            <div class="checkoutSection_coupon_item_header">
                                <div class="checkoutSection_coupon_item_header_info">
                                    <span class="checkoutSection_coupon_item_price">${discountPriceFormatted}%</span>
                                    <span class="checkoutSection_coupon_item_title">${matchingCoupon.name}</span>
                                </div>
                                <div>
                                    <input class="checkoutSection_coupon_item_header_checkbox" id="coupon-box" type="checkbox" data-coupon-id="${matchingCoupon.coupon_ID}" data-discount-type="${matchingCoupon.discount_type}" data-discount-price="${matchingCoupon.discount_price}">
                                </div>
                            </div>
                            <div class="checkoutSection_coupon_item_body_info">
                                <div class="checkoutSection_coupon_item_body_info_coupon_title">
                                    ${matchingCoupon.name}
                                </div>
                                <div class="checkoutSection_coupon_item_body_info_coupon_expireDate">
                                    유효기간: ${new Date(matchingCoupon.valid_until).toLocaleDateString()}
                                </div>
                                <div class="checkoutSection_coupon_item_body_info_coupon_useGuide">계정당 1회 사용 가능(중복 사용 불가)</div>
                            </div>
                        </div>`;

                    // 쿠폰 아이템을 추가할 HTML 요소에 삽입
                    document.querySelector('.added_coupon_items').innerHTML = couponHTML;
                } else {
                    alert('쿠폰 번호가 일치하지 않습니다.');
                }
            })
            .catch(error => {
                console.error('Error fetching coupon data:', error);
                alert('쿠폰 데이터를 가져오는 중 오류가 발생했습니다.');
            });
    });
}

