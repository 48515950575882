export function shareKakao(templateId) {
  fetch(`/api_DBInsert/${templateId}`)
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      console.log(data);
      let titleImgURL = data.imageUrl;
      let imageUrl = data.kakaoimageUrl;
      let invitation_title = data.invitation_title;
      let weddingDateOrigin = data.weddingDate.trim();
      let weddingDate = weddingDateOrigin
        .replace(/(\d{4}년\s+)/, '')
        .replace(/0?(\d+)월 0?(\d+)일\s*월요일/, '$1월 $2일 (월)')
        .replace(/0?(\d+)월 0?(\d+)일\s*화요일/, '$1월 $2일 (화)')
        .replace(/0?(\d+)월 0?(\d+)일\s*수요일/, '$1월 $2일 (수)')
        .replace(/0?(\d+)월 0?(\d+)일\s*목요일/, '$1월 $2일 (목)')
        .replace(/0?(\d+)월 0?(\d+)일\s*금요일/, '$1월 $2일 (금)')
        .replace(/0?(\d+)월 0?(\d+)일\s*토요일/, '$1월 $2일 (토)')
        .replace(/0?(\d+)월 0?(\d+)일\s*일요일/, '$1월 $2일 (일)')
        .replace(/0?(\d+)월 0?(\d+)일/, '$1월 $2일');
      
      if (!imageUrl || imageUrl === '') {
        imageUrl = titleImgURL;
      }

      let wedding_AMPM = data.wedding_AMPM;
      let wedding_time = data.wedding_time;
      let wedding_minute = data.wedding_minute === '00분' ? '' : data.wedding_minute;
      let wedding_location = data.wedding_location ? data.wedding_location : '보테가마지오';
      let wedding_address = data.weddingAddress ? data.weddingAddress : '서울시 강남구 역삼동 123-45';
      let wedding_location_hall = data.wedding_location_hall ? data.wedding_location_hall : '로스타뇨홀';

      let baseURL = window.location.origin;
      let shareURL = `${baseURL}/api_GetInvitation/${templateId}`;

      if (data.location_img_status === 1) {
        // Use location template with View Location button
        Kakao.Share.sendDefault({
          objectType: 'location',
          address: wedding_address,
          addressTitle: wedding_location_hall,
          content: {
            title: invitation_title,
            description: `${weddingDate} ${wedding_AMPM} ${wedding_time} ${wedding_minute}\n${wedding_location} ${wedding_location_hall}`,
            imageUrl: imageUrl,
            link: {
              mobileWebUrl: shareURL,
              webUrl: shareURL,
            },
          },
          buttons: [
            {
              title: '모바일 청첩장',
              link: {
                mobileWebUrl: shareURL,
                webUrl: shareURL,
              },
            },
            {
              title: '위치보기',
              link: {
                mobileWebUrl: shareURL,
                webUrl: shareURL,
              },
            },
          ],
        });
      } else {
        // Use feed template without View Location button
        Kakao.Share.sendDefault({
          objectType: 'feed',
          content: {
            title: invitation_title,
            description: `${weddingDate} ${wedding_AMPM} ${wedding_time} ${wedding_minute}\n${wedding_location} ${wedding_location_hall}`,
            imageUrl: imageUrl,
            link: {
              mobileWebUrl: shareURL,
              webUrl: shareURL,
            },
          },
          buttons: [
            {
              title: '모바일 청첩장',
              link: {
                mobileWebUrl: shareURL,
                webUrl: shareURL,
              },
            },
          ],
        });
      }
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error.message);
    });
}
