import { GetDBInfo } from "../../api/api_dbInsert";

export function template_board(templateId) {

    // 비동기적으로 DB에서 정보를 가져오고, 결과가 도착하면 방명록을 보여줄지 말지에 대한 로직을 작성합니다.
    GetDBInfo(templateId).then(dbInfo => {
        console.log(dbInfo.board_toggle);

        // dbInfo.board_toggle이 0이면 BoardSection 클래스의 display를 none으로 설정하고, active 클래스를 제거
        const boardSections = document.querySelectorAll('.BoardSection');
        const shouldDisplay = dbInfo.board_toggle !== 0;
        boardSections.forEach(section => {
            section.style.display = shouldDisplay ? '' : 'none'; // 초기 상태 설정
        });

        // 방명록 보여주기 on&off 토글 스위치 로직 추가
        let switch_board = document.getElementById('toggleSwitch_board');
        if (switch_board) {
            if (!shouldDisplay) {
                switch_board.classList.remove('active'); // active 클래스 제거
            }

            switch_board.onclick = () => {
                const isHidden = boardSections[0].style.display === 'none'; // 첫 번째 섹션의 display 상태를 기준으로 판단
                boardSections.forEach(section => {
                    section.style.display = isHidden ? '' : 'none'; // 모든 섹션의 display 상태를 토글
                });
                switch_board.classList.toggle('active'); // switch_board의 active 클래스 토글
            };
        }
    }).catch(error => {
        console.error('DB 정보를 가져오는데 실패했습니다:', error);
    });





    function toggleActiveClass(element) {
        if (element) {
            element.classList.toggle('is-active');
        }
    }

    // 가정: CancelDimmed_Create 등의 요소들은 HTML에 존재한다고 가정합니다.
    let CancelDimmed_Create = document.getElementById('CloseAlert_Create');
    let CancelDimmed_View = document.getElementById('CloseAlert_View');
    let CancelDimmed_Del = document.getElementById('CloseAlert_Del');
    let BoardCreateBtn = document.getElementById('BoardCreateBtn');
    let BoardViewBtn = document.getElementById('BoardViewBtn');
    let BoardViewDimmed = document.getElementById('Board_View_Dimmed');
    let BoardDelDimmed = document.getElementById('Board_Del_Dimmed');
    let BoardDimmed = document.getElementById('Board_Create_Dimmed');

    let spinner_mini = document.querySelector('.spinner_mini');

    // 스피너 관련 함수들
    function showSpinner() {
        if (spinner_mini) {
            spinner_mini.style.display = 'block';
        }
    }

    function hideSpinner() {
        if (spinner_mini) {
            spinner_mini.style.display = 'none';
        }
    }

    // 방명록 목록 업데이트 함수
    function updateBoardList(isAlertVisible = false) {
        let containerSelector = isAlertVisible ? '.BoardArea_Alert' : '.BoardArea';
        let BoardSection = document.querySelector(containerSelector);
        showSpinner(); // 스피너를 보여주기 시작

        fetch(`/api_GetBoard/${templateId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data.length === 0) {
                    BoardSection.innerHTML = `<div class="BoardContents">남겨진 방명록이 없습니다.</div>`;
                    console.log(containerSelector);
                    let Board_ContentsScroll = document.querySelector('.BoardArea_Alert');
                    Board_ContentsScroll.style.overflowY = "hidden";
                } else {
                    let Board_ContentsScroll = document.querySelector('.BoardArea_Alert');
                    Board_ContentsScroll.style.overflowY = "scroll";
                    BoardSection.innerHTML = '';
                    
                    // 초기 10개 항목만 표시
                    let initialDisplayCount = 10;
                    let currentDisplayCount = 0;

                    const displayMoreItems = () => {
                        console.log(`Displaying items from ${currentDisplayCount} to ${currentDisplayCount + initialDisplayCount}`);
                        const itemsToShow = data.slice(currentDisplayCount, currentDisplayCount + initialDisplayCount);
                        itemsToShow.forEach(BoardData => {
                            BoardSection.innerHTML += `
                                <div class="BoardItem">
                                    <div class="BoardText">
                                        <div class="BoardFrom">
                                            <div class="From">
                                                <img class="board_fromImg" src="../../../Resource/templates/invitation_graphic/mark_from.svg" alt=""></div>
                                            <div class="FromTarget">${BoardData.name}</div>
                                        </div>
                                        <div class="BoardBody">
                                            ${BoardData.contents}
                                        </div>
                                    </div>
                                    <div class="BoardDelete">
                                        <img class="BoardIconDel"
                                             src="../Resource/assets/Icon/Del.svg" alt="">
                                    </div>
                                </div>
                            `;
                        });
                        currentDisplayCount += itemsToShow.length;
                        if (currentDisplayCount >= data.length) {
                            console.log('All items displayed, hiding load more button');
                            loadMoreBtn.style.display = 'none';
                        } else {
                            loadMoreBtn.style.display = 'block';
                            BoardSection.appendChild(loadMoreBtn);
                        }
                    };

                    // "더보기" 버튼 생성
                    let loadMoreBtn = document.querySelector('.boardloadMoreBtn');
                    if (!loadMoreBtn) {
                        loadMoreBtn = document.createElement('button');
                        loadMoreBtn.className = 'boardloadMoreBtn';
                        loadMoreBtn.textContent = '방명록 더보기';
                        loadMoreBtn.addEventListener('click', () => {
                            console.log('Load more button clicked');
                            // 기존 버튼 제거
                            if (loadMoreBtn.parentNode) {
                                loadMoreBtn.parentNode.removeChild(loadMoreBtn);
                            }
                            displayMoreItems();
                            // "더보기" 버튼을 리스트의 맨 아래로 이동
                            BoardSection.appendChild(loadMoreBtn);
                        });
                    }

                    displayMoreItems();
                }
                setDeleteButtonListener();
            })
            .catch(error => {
                console.error('Error:', error);
            })
            .finally(() => {
                hideSpinner(); // 스피너 숨김
            });
    }

    // 방명록 목록 보기 버튼&취소
    if (BoardViewBtn) {
        BoardViewBtn.addEventListener('click', () => {
            toggleActiveClass(BoardViewDimmed);
            updateBoardList(true); // 방명록 업데이트 함수 호출, Alert 영역으로 지정
        });
    }

    // 방명록 남기기 버튼&취소
    if (BoardCreateBtn) {
        BoardCreateBtn.addEventListener('click', () => {
            toggleActiveClass(BoardDimmed);
        });
    }

    // 취소 버튼 이벤트 리스너
    [CancelDimmed_Create, CancelDimmed_View, CancelDimmed_Del].forEach(btn => {
        if (btn) {
            btn.addEventListener('click', () => {
                let targetDimmed = btn === CancelDimmed_Create ? BoardDimmed :
                    btn === CancelDimmed_View ? BoardViewDimmed : BoardDelDimmed;
                toggleActiveClass(targetDimmed);
            });
        }
    });

    // 방명록 저장 버튼 리스너
    if (SaveBoard) {
        SaveBoard.addEventListener('click', (event) => {
            event.preventDefault(); // 기본 동작 방지 (페이지 새로고침)

            const Board_Writer = document.getElementById('Board_Writer');
            const Board_Contents = document.getElementById('Board_Contents');
            const Board_Password = document.getElementById('Board_Password');

            const Board_Writer_Data = Board_Writer.value;
            const Board_Contents_Data = Board_Contents.value;
            const Board_Password_Data = Board_Password.value;

            if (Board_Writer_Data === '') {
                alert('작성자 명을 입력해주세요');
            } else if (Board_Contents_Data === '') {
                alert('방명록 내용을 입력해주세요');
            } else if (Board_Password_Data === '') {
                alert('비밀번호를 입력해주세요');
            } else {
                // POST 요청 보내기
                const data = {
                    template_ID: templateId,
                    Board_Writer_Data: Board_Writer_Data,
                    Board_Contents_Data: Board_Contents_Data,
                    Board_Password_Data: Board_Password_Data,
                };

                fetch('/api_Board/CreateBoard', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data),
                })
                    .then(response => {
                        if (response.ok) {
                            console.log('게시판 데이터 전송 성공');
                            alert('작성하신 글이 방명록에 등록되었습니다.');
                            BoardDimmed.classList.toggle('is-active');

                            // 방명록 목록 업데이트
                            updateBoardList();
                        } else {
                            console.error('게시판 데이터 전송 실패');
                        }
                    })
                    .catch(error => {
                        console.error('게시판 데이터 전송 실패:', error);
                    });
            }
        });
    }

    // 처음 로드할 때 한 번 호출하여 방명록 목록 업데이트
    updateBoardList();

    // 삭제 버튼 리스너 설정 함수
    function setDeleteButtonListener() {
        let BoardDelBtn = document.querySelectorAll('.BoardDelete');

        BoardDelBtn.forEach((btn) => {
            // 기존에 설정된 리스너를 제거
            btn.removeEventListener('click', deleteButtonHandler);

            // 새로운 리스너를 추가
            btn.addEventListener('click', deleteButtonHandler);
        });
    }

    // 삭제 버튼 이벤트 핸들러
    function deleteButtonHandler(event) {
        toggleActiveClass(BoardDelDimmed);
        const BoardItem = event.target.closest('.BoardItem');
        const BoardFrom = BoardItem.querySelector('.FromTarget').innerText;

        const DeleteConfirm = document.getElementById('DeleteConfirm');
        DeleteConfirm.onclick = () => {
            let password = document.getElementById('deletePassword').value;
            deleteBoard(BoardFrom, password);
        };
    }

    // 방명록 삭제 함수
    function deleteBoard(name, password) {
        const DeletAPI = `/api_DeleteBoard/${templateId}`;

        fetch(DeletAPI, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ name, password })
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    alert(data.message);
                    BoardDelDimmed.classList.toggle('is-active');

                    // 삭제 성공 후 두 영역 모두 업데이트
                    updateBoardList(true); // 'BoardViewDimmed' 영역의 목록 업데이트
                    updateBoardList(false); // 'BoardArea' 영역의 목록 업데이트
                } else {
                    alert(data.message);
                    BoardDelDimmed.classList.toggle('is-active');
                }
            })
            .catch(error => {
                console.error('Error:', error);
                alert('서버 오류: 삭제 실패했습니다.');
            });
    }


}

